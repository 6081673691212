/*
Authors: Rahul Abhishek 
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://api.okkji.co.in/index.php/',
  mediaURL: 'https://api.okkji.co.in/uploads/',
  onesignal: {
    appId: '869f4d31-d42f-4986-92dd-430406526cbc',
    googleProjectNumber: '157768494844',
    restKey: 'MjQxMThlMDItY2M3ZC00YjZiLWJmNDEtZjEzMDgyYjY2MDU1' 
  },
  general: {
    symbol: '₹',
    code: 'INR'
  },
  authToken: '123456789'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
